<template>
    <v-container>
        <validation-observer tag="div" ref="observer">
            <v-card class="mb-3">
                <v-card-text>
                    <v-row>
                        <v-col>
                            <div class="title">Thanks</div>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="6" md="3">
                            Thanks text!
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </validation-observer>
    </v-container>
</template>

<script>

    export default {
        name: 'Thanks',
        mounted() {
            this.$root.$children[0].hideLoading()
        },
    }
</script>

<style>
</style>